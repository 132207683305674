import { UseControllerProps, useController } from 'react-hook-form';
import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons";
import "../../styles/FormInput.css"
import { DateInput2 } from "@blueprintjs/datetime2/lib/esm/components/date-input2/dateInput2";
import { NumericInput } from "@blueprintjs/core/lib/esm/components/forms/numericInput";
import { MenuItem } from "@blueprintjs/core/lib/esm/components/menu/menuItem";
import { useCallback, useEffect } from 'react';
import { Suggest2 } from '@blueprintjs/select/lib/esm/components/suggest/suggest2';
import { useState, useRef } from 'react';
import { Popover2 } from "@blueprintjs/popover2/lib/esm/popover2";
import { Toaster } from "../../utils/Toaster"
import { Checkbox, Spinner } from "@blueprintjs/core";
import { useSampleDialogStore } from "../../stores/SampleDialogStore";
import { SampleAPI } from '../../api/DataAPI';
import { InputGroup, FormGroup } from '@blueprintjs/core';
import { Tooltip2 } from "@blueprintjs/popover2/lib/esm/tooltip2"
import { FieldProps } from '../../types/OrganismTypes';
import { MultiSelect2 , Select2 } from "@blueprintjs/select"; 

interface FormInputProps extends UseControllerProps<any> {
  fieldProps: FieldProps,
  disabled?: boolean,
  required?: boolean,
  hideLable?: boolean,
  sampleAPI?: SampleAPI,
  updateKey?: (key: string) => void,
  register?: any,
  setValue?: any,
  adminEntity?: boolean
}

export function FormInput(props: FormInputProps) {

  const inputRef = useRef<HTMLElement>(null)
  const { selectedSampleId, sample } = useSampleDialogStore();
  const { field, fieldState } = useController(props)
  const [isUnknown, setIsUnknown] = useState(false);

  const fieldProps = props.fieldProps
  const inputErrorClass = fieldState.error ? "bp4-intent-danger" : ""

  const formatDate = useCallback((date: Date) => `${date.getUTCFullYear()}-${('0' + (date.getUTCMonth() + 1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)}`, []);

  const parseDate = useCallback((str: string) => {
    const [year, month, day] = str.split('-').map(Number);
    return new Date(year, month - 1, day);
  }, []);

  const [selectedItem, setSelectedItem] = useState<string | undefined>(field.value);
  const [newTag, setNewTag] = useState<string | undefined>(undefined);
  const [enterclicked, setEnterclicked] = useState<boolean>(false);
  const tags = fieldProps.enum || [];
  const [newKey, setNewKey] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const [menuCollapse, setMenuCollapse] = useState(true)
  const [lastValidDate, setLastValidDate] = useState<Date | null>(field.value || null);

  let finalTags: (string)[] = tags;
  const manualFocus = () => {
    if (inputRef) {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }
  }
  const manualSetValue = (event: any, path: string) => {
    const fieldName = path.split('.')[2]
    props.setValue(fieldName, event)
    manualFocus()
  }
  const fieldName = props.adminEntity ? (fieldProps.path) : (fieldProps.path?.split('.')[2])

  useEffect(() => {
    if (field.value?.toString().trim().toLowerCase() === "unknown") {
      setIsUnknown(true)
    }
  }, [field.value])
  
  const control = (() => {
    if (fieldProps.freeEntry) {
      return (
        <div>
          <Suggest2<string>
            inputValueRenderer={(item) => item}
            items={finalTags}
            itemRenderer={(tag, { handleClick, modifiers }) => {

              let text = tag

              if (newTag || newTag === '') {
                return (
                  <MenuItem
                    active={false}
                    key={text}
                    onClick={handleClick}
                    text={text}
                  />
                )
              }

              return (
                <MenuItem
                  active={modifiers.active}
                  key={text}
                  onClick={handleClick}
                  text={text}
                />
              )
            }}
            selectedItem={selectedItem}
            disabled={props.disabled}
            onQueryChange={(value) => {
              setSelectedItem(value);
              if (!tags.includes(value)) {
                setNewTag(value);
              } else {
                setNewTag(undefined);
              }
            }}
            inputProps={{
              ...field,
              placeholder: 'Search...',
              className: `${inputErrorClass}`,
              onKeyDown: (event) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  if ((newTag && !tags.includes(newTag)) || newTag?.trim() === '') {
                    setSelectedItem(newTag);
                    setEnterclicked(true);
                  }
                }
              }
            }}
            onItemSelect={(value) => {
              if ((newTag || newTag?.trim() === '') && enterclicked) {
                setSelectedItem(newTag);
                field.onChange({ target: { value: newTag } });
              }
              else {
                setSelectedItem(value);
                field.onChange({ target: { value: value } });
              }
              setEnterclicked(false);

            }}
          />
        </div>
      );
    }
    
    if(fieldProps.type === 'select2' && fieldProps.enum){
      return (
        <Select2 
                items={fieldProps.enum} 
                itemRenderer={(val, itemProps) => { 
                    return ( 
                        <MenuItem 
                            key={val} 
                            text={val} 
                            onClick={(elm: any) => { 
                                field.onChange(elm.target.textContent)
                                setMenuCollapse(!menuCollapse)
                            }} 
                        /> 
                    ); 
                }} 
                onItemSelect={() => { }} 
                onActiveItemChange={()=>{
                }}
                initialContent={fieldProps.default}
            > 
                <Button aria-label={`input field for ${props.name}`} icon={menuCollapse? 'chevron-down' : 'chevron-up'} text={field.value} onClick={() => setMenuCollapse(!menuCollapse)}/> 
            </Select2>
      )
    }
    if (fieldProps.enum)
      return <div className="bp4-html-select">
        <select aria-label={`input field for ${props.name}`} {...props.register ? { ...props.register(fieldName) } : {}} disabled={props.disabled} onChange={field.onChange} value={field.value || fieldProps.default}>
          <option value={fieldProps.default}>{fieldProps.default}</option>
          {fieldProps.enum.map((v, i) => <option key={i} value={v}>{v}</option>)}
        </select>
        <span className="bp4-icon bp4-icon-double-caret-vertical"></span>
      </div>
    if (fieldProps.type === "date"){
      if(fieldProps.allowUnknown)
        return (
          <div>
            <DateInput2
              formatDate={formatDate}
              onChange={field.onChange}
              parseDate={parseDate}
              placeholder="YYYY-MM-DD"
              value={isUnknown ? null : field.value}
              disabled={isUnknown || props.disabled}
            />
            <Checkbox 
              checked={isUnknown} 
              label="Unknown" 
              onChange={() => {
                if (isUnknown) {
                  field.onChange(lastValidDate)
                } else {
                  setLastValidDate(field.value)
                  field.onChange("Unknown")
                }
                setIsUnknown(!isUnknown)
              }}
            />
          </div>
        )
      else  
        return <DateInput2
          formatDate={formatDate}
          onChange={field.onChange}
          parseDate={parseDate}
          placeholder="YYYY-MM-DD"
          value={field.value}
          disabled={props.disabled}
        />
    }
    if (fieldProps.type === "number")
      return <NumericInput
        alt={`input field for ${props.name}`}
        allowNumericCharactersOnly={true}
        onValueChange={field.onChange}
        value={field.value}
        max={typeof (fieldProps.max) === "number" ? fieldProps.max : undefined}
        min={typeof (fieldProps.min) === "number" ? fieldProps.min : undefined}
        disabled={props.disabled}
      />
    if (fieldProps.type === "numbernull")
      return <NumericInput
        alt={`input field for ${props.name}`}
        inputRef={inputRef}
        {...props.register ? { ...props.register(fieldProps.path?.split('.')[2]) } : {}}
        allowNumericCharactersOnly={false}
        onValueChange={(event) => manualSetValue(event, fieldProps.path || "")}
        value={field.value}
        max={typeof (fieldProps.max) === "number" ? fieldProps.max : undefined}
        min={typeof (fieldProps.min) === "number" ? fieldProps.min : undefined}
        disabled={props.disabled}
      />

    return <input alt={`input field for ${props.name}`} type="text" disabled={props.disabled} className={`bp4-input ${inputErrorClass}`} {...field} />
  })()


  const saveNewKey = async () => {

    setLoading(true)

    if (props.sampleAPI) {
      try {
        await props.sampleAPI.updateIdentifier(selectedSampleId || "", {identifier: newKey})
        Toaster.show({ icon: "tick", message: `Sample Key updated successfully`, intent: "success" })
        setShow(false)
        setLoading(false)
        setNewKey("")
        if (props.updateKey) {
          props.updateKey(newKey)
        }
      } catch (error) {
        Toaster.show({ icon: "error", message: `could not save new id. Server response: ${error}`, intent: "danger" })
        setLoading(false)
      }
    }
  }

  return <div className='input-ctr'>
    <label className='bp4-label'>
      <div className='inline-flex'>
        <Tooltip2 content={fieldProps.description}>
          <div className="label-container">
            {props.hideLable ? '' : fieldProps.title || props.name}
            {props.required && <span className='required-asterisk'>*</span>}
          </div>
        </Tooltip2>
        <div className='inline-flex' style={{ gap: 5 }}>
          <div className='control-ctr'>{control}</div>
          {fieldProps.title === "Key" && (selectedSampleId && !sample?.nationalDatabase && sample?.pipelineRunStatus !== 'Running') ? <Popover2
            position="bottom"
            onClosing={() => setNewKey("")}
            isOpen={show}
            content={
              <div>
                <div style={{ padding: '10px' }}>
                  <FormGroup
                    helperText="Enter a new key and click save to confirm.."
                  >
                    <InputGroup placeholder="Enter new key value.." onChange={(e) => setNewKey(e.target.value)} />
                  </FormGroup>
                  <div style={{ marginTop: "10px", display: 'flex', gap: "5px", justifyContent: 'right' }}>
                    <Button intent='primary' className="bp4-icon-floppy-disk" onClick={saveNewKey} disabled={loading}>
                      <span style={{ display: 'flex', flexDirection: 'row' }}>
                        {!loading ? "Save" : "Saving..."}
                        {loading ? <Spinner style={{ marginLeft: '5px' }} size={15}></Spinner> : null}
                      </span>
                    </Button>
                    <Button onClick={() => setShow(false)} intent="danger">
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            }
            renderTarget={({ isOpen, ref, ...targetProps }) => (
              <Button
                {...targetProps}
                elementRef={ref}
                onClick={() => (setShow(!show))}
                minimal
                icon="edit"
              >
              </Button>
            )}
          /> : <></>}
        </div>
      </div>
      <div className='errorText'>{fieldState.error?.message}</div>
    </label>
  </div>
}
